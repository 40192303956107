<script lang="ts">

	import { toast } from "svelte-sonner";
	import { goto } from "$app/navigation";
	import { apiFetch } from "$lib/utils/fetch";
	import { user } from "$lib/stores/user"
	import Card from "./Card.svelte";
	import { onBeforeClose } from "svelte-modals";
	import Link from "../ui/Link.svelte";
	import LoginButton from "./LoginButton.svelte";
	import { onMount } from "svelte";

	type State = 'default' | 'success' | 'loading' | 'error';
	export let state: State = 'default';
    state = "default"

	let email = "user@example.com"

	onBeforeClose(() => {
        if (state === "default" || state === "loading") {
            return false
        }
    })

	onMount(() => {
		apiFetch("/auth/email")
			.then((res) => {
				email = res.data
			})
	})

	async function handleSendLink() {
		state = "loading"
		await apiFetch(`/auth/resend_activate`, {
			method: 'POST'
		})
			.then(() => {
				state = "success"
				toast.success("Länk skickad")
			})
			.catch((err) => {
				state = "error"
				setTimeout(() => {
					state = "default"
				}, 2000)
			});
	}
</script>

<Card>
	<div>
		<h1 class="text-2xl font-semibold text-left text-white">Aktivera ditt konto</h1>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Problem?</h4>
			<Link href="https://discord.playpark.se">Kontakta oss</Link>
		</span>
	</div>
	<p class="text-white">Hej, <Link href="/profile/{$user?.username}">{$user?.username}</Link> du behöver aktivera ditt konto. För att aktivera ditt konto så klickar du på länken som vi har skickat till dig på <Link href={`mailto:${email}`}>{email}</Link></p> 
	<p class="text-white">
		När du har aktiverat ditt konto kan du ladda om sidan för att fortsätta.
	</p>
	<p class="text-white">
		Om du inte har fått något mail, kolla din skräppost eller begär en ny länk.
	</p>
	<LoginButton
				style="steam"
				state={state}
				functionToRun={handleSendLink}
				type="submit"
			>
				<div class="relative flex items-center justify-center w-full px-4">Skicka ny länk</div>
			</LoginButton>
    
</Card>