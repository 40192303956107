<script lang="ts">
	import { apiFetch, type apiResponse } from '$lib/utils/fetch';
	import { useQueryClient, createQuery } from '@tanstack/svelte-query';
	import { openModal, closeAllModals, closeModal, modals } from 'svelte-modals';
    import Modal from '$lib/components/Modal.svelte';
    import Renew from '$lib/components/auth/Renew.svelte';
    import { authenticated, user } from '$lib/stores/user';
    import { derived } from 'svelte/store';
	import ActivateAccountReminder from './ActivateAccountReminder.svelte';
    import { page } from '$app/stores';

    interface renewCheckResponse extends apiResponse {
        data: {
            member_current_year: boolean;
            message?: string;
        }
    }
    const client = useQueryClient();
    

    const renewCheck = createQuery<{ renewcheck: renewCheckResponse }>({
    queryKey: ['statuscheck'],
    enabled: $authenticated,    
    queryFn: async () => 
        await apiFetch("/auth/renew_status",{
            method: 'GET',
        })
            .then((fetchData) => {
                return { renewcheck: fetchData };
            })
            .catch((error) => {
                return { renewcheck: error };
            })
    })

    $: switch ($authenticated) {
        case true:
            // console.log("Authenticated")
            client.invalidateQueries({ queryKey: ['statuscheck'] });
            client.fetchQuery({ queryKey: ['statuscheck'] });
            break;
    }

    // Reactively check if the user needs to renew their membership
    
    const renewCheckData = derived(renewCheck, $renewCheck => !$renewCheck.data?.renewcheck.data.member_current_year && $renewCheck.data?.renewcheck.data.member_current_year !== undefined && $renewCheck.data?.renewcheck.data.member_current_year !== null && $renewCheck.isFetching === false);

    const activevateAccountCheck = derived(renewCheck, $renewCheck => $renewCheck.data?.renewcheck.data.message === "Your account is not active" && $renewCheck.isFetching === false);
    

    $: switch ($renewCheckData) {
        case true:
        // console.log("Renew check is true")
        openModal(Modal, { component: Renew, props: {} }, {
            replace: true,
        });
            break;
        case false:
            // console.log("Renew check is false")
            break;
        case undefined:
            break;
    }

    $: switch ($activevateAccountCheck) {
        case true:
        if ($authenticated && $page.url.pathname.includes('activate')) {
            break;
        }
        openModal(Modal, { component: ActivateAccountReminder, props: {} }, {
            replace: true,
        });
            break;
        case false:
            break;
        case undefined:
            break;
    }
    
    
</script>