<script lang="ts">
	import './styles.css';
	import '../app.css';
	import './fonts.css';
	import { Modals, closeModal } from 'svelte-modals';
	import { Toaster } from 'svelte-sonner';
	import '@fontsource-variable/inter';

	import { browser } from '$app/environment';
	import { QueryClient, QueryClientProvider } from '@tanstack/svelte-query';
	import { SvelteQueryDevtools } from '@tanstack/svelte-query-devtools';
	import RenewCheck from '$lib/components/auth/statusCheck.svelte';

	// Ensure the app is updated at all times
	import { beforeNavigate } from '$app/navigation';
	import { updated } from '$app/stores';

	beforeNavigate(({ willUnload, to }) => {
		if ($updated && !willUnload && to?.url) {
			location.href = to.url.href;
		}
	});

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				enabled: browser
			}
		}
	});
</script>

<svelte:head>
	<title>Playpark</title>
	<meta name="description" content="Playpark" />
	<meta name="og:title" content="Playpark" />
	<meta name="og:description" content="Playpark Spelförening" />
</svelte:head>

<QueryClientProvider client={queryClient}>
	<div class="app">
		<main>
			<div class="w-full">
				<slot />
			</div>
		</main>
	</div>
	<RenewCheck />
	<SvelteQueryDevtools />
</QueryClientProvider>

<Modals>
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<div slot="backdrop" class="backdrop" on:click={closeModal} on:keypress />
</Modals>

<Toaster
	position="bottom-center"
	class="toaster group"
	toastOptions={{
		classes: {
			toast:
				'group toast group-[.toaster]:bg-zinc-900/70 backdrop-blur group-[.toaster]:text-white group-[.toaster]:border-zinc-800 group-[.toaster]:shadow-lg group-[.toaster]:drop-shadow'
		}
	}}
/>

<style lang="scss">
	.backdrop {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(4px);
		z-index: 1;
	}
	.app {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background: #000;
		width: 100vw;
		overflow: scroll;
	}

	main {
		flex: 1;
		display: flex;
		width: 100%;
		box-sizing: border-box;
	}

	:global(:root) {
		font-family: 'Inter Variable', sans-serif;
	}
</style>
