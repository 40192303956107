import posthog from 'posthog-js';
import { browser } from '$app/environment';

export const load = async () => {
	if (browser) {
		posthog.init('phc_iEEaUAOwn6Ay8gV1JwYhrIrFNk2833O6ccb4DzPIBnw', {
			api_host: 'https://analytics.playpark.se',
			person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
		});
	}
	return;
};
